import Vuex from 'vuex'

export const store = new Vuex.Store({
    state: {
        isAuth: false,
        isAdmin: false,
        profileData: null,
        admins: {}
    },
    mutations: {
        authorized(state){
            state.isAuth = true
        },
        unauthorized(state){
            state.isAuth = false
        },
        checkIsAdmin(state, value) {
            state.isAdmin = value;
        },
        getProfileData(state, value) {
            state.profileData = value;
        },
        setAdminOrderAuth(state, { adminId, isAuthenticated }) {
            state.admins[adminId] = state.admins[adminId] || {};
            state.admins[adminId].isAuthenticatedOrder = isAuthenticated;
        },
        resetAdminOrderAuth(state, adminId) {
            state.admins[adminId] = state.admins[adminId] || {};
            state.admins[adminId].isAuthenticatedOrder = false;
        }
    },
    actions: {
        authorized (context) {
            context.commit('authorized')
        },
        unauthorized (context) {
            context.commit('unauthorized')
        },
        checkIsAdmin (context, value) {
            context.commit('checkIsAdmin', value)
        },
        getProfileData({ commit }, value) {
            commit('getProfileData', value)
        },
        adminOrderAuthenticate({ commit }, value) {
            commit('adminOrderAuthenticated', value)
        }
    }
})
