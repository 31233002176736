import { createRouter, createWebHistory } from 'vue-router';
import routes from "@/router/routes";
import {store} from "@/vuex/store";
import ApiService, {ApiAdminService} from "@/services/ApiService";

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior () {
        return { top: 0, left: 0 }
    }
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token') || '';

    if (token) {
        store.dispatch('authorized');
    } else {
        store.dispatch('unauthorized');
    }

    if (to.meta.requiresAuth) {
        if (!isUserAuthorized()) {
            next('/login');
        } else {
            if (!to.meta.requiresTFA) {
                ApiService.getProfileData().then(res => {
                    if (res) {
                        store.dispatch('getProfileData', res.data);

                        if (res.data?.roles.includes("ROLE_ADMIN")) {
                            store.dispatch('checkIsAdmin', true);
                        } else {
                            store.dispatch('checkIsAdmin', false);
                        }
                        next();
                    }

                    return res;
                }).catch(err => {
                    if (err.response.data.error.code === 401) {
                        next('/login');
                    } else {
                        throw err;
                    }
                });
            } else {
                next();
            }
        }

        if (to.meta.offersSecureEntry) {
            ApiAdminService.secureEntry(true).then((res) => {
                if (res) {
                    next();
                } else {
                    next('/app/admin/offers');
                }
            }).catch(err => {
                throw err;
            });
        }
    } else {
        next();
    }
    document.title = to.meta?.title ? to.meta?.title + ' - GCB' : 'GCB'

});

function isUserAuthorized() {
    return store.state.isAuth;
}

export default router;
