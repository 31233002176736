import { createApp } from 'vue'
import './assets/styles/reset.css';
import './assets/styles/bootstrap-grid.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import 'element-plus/dist/index.css'
import '@/assets/styles/main.scss'
import '@/assets/styles/dashboard.scss'

import App from './App.vue'
import router from './router';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

createApp(App)
    .use(router)
    .use(VueTelInput)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyCfeE5yKc8aujpUTqCSy2HjS2CRV1pJfQI',
        },
    })
    .mount('#app')
